* {
  padding: 0px;
  margin: 0px;
  font-family: Uniform;
  /* font-size: 11px; */
}
.MapChartRow {
  display: flex;
  flex-direction: row;
}
#MapSection {
  font-size: 11px;
}
.attendanceRateBox {
  margin-top: 5px;
  height: 78px;
}

body {

}
.HideClass {
  display: none !important;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(133, 133, 133);
}
.MAP_Legend {
  padding: 20px;
  padding-left: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.DashboardResourceImage {
  width: 100%;
  height: 250px;
}
.resouceTitlee {
  font: normal normal normal 30px/41px Uniform-Medium;
  letter-spacing: 0px;
  color: #020a58;
}
.LeftLogin{
  width: 25%;
  margin-left: 150px;
}
.LoginGateway{
  font: normal normal normal 25px Uniform Bold_Static;
  letter-spacing: 0px;
  color: #020a58;
}
.loginpage {

  height: 100%;
}
.LWrap{
  margin-top: 15px;
  display: flex;
  flex-direction: column;

}
.mtop10{
  margin-top:20px;
}
.loginCenter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loginHeader{
  width: 100%;
  text-align: center;
  height: 100px;;
}
.HeaderLogo{
  margin-top: 30px;
}
.LoginWelcome{
  font: normal normal normal 50px Uniform Bold_Static;
  letter-spacing: 0px;
  color: #020a58;
}
.logoLogin{
  width: 250px;
  height: 175px;
  margin-top: 10px;;
}
.loginPagePaper {
  width: 300px;
  height: 400px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset -55px 203px 99px #00000029;
  border-radius: 25px !important;
  position: absolute;
  left: 150px;
  margin-top: 150px;
  padding: 50px;
  display: flex;
  flex-direction: column;
}
.resourceFillBox {
  width: 644px;
  height: 480px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 10px;
}
.dec1{
  width: 150px;
  height: 70px;;
}
.LoginTextbox{
  margin: 5px;
  width:300px;
  height: 30px;
  padding: 5px;
  border: 2px solid #020a58;
  font: normal normal normal 20px Uniform_Static;
}
.LoginRememeberMe{
  margin: 5px;
  width:300px;
  height: 30px;
  padding: 5px;
  color:#020a58;
  font: normal normal normal 20px Uniform_Static;
}
.loginBtn{
  margin: 5px;
  color:#FCBF03;
  border: 2px solid #FCBF03;
  width:300px;
 cursor: pointer;
  padding: 10px 5px;
  font: normal normal normal 20px Uniform Bold_Static;
  letter-spacing: 0px;
  color: #FCBE2C;

}
.YellowArrowInBtn{
 margin-left: 270px;
 margin-top:7px;
}
.loginBtn:hover{
  border-bottom: 4px solid #FCBF03;
  border-left: 4px solid #FCBF03;
}
.smallFlower {
  position: absolute;
  top: 10px;
}
.smallFlowerAR {
  position: absolute;
  top: 10px;
}
.largeFlower {
  position: absolute;
  top: -50px;
  right: 0px;
}
.largeFlowerAr {
  position: absolute;
  top: -50px;
  left: 0px;
}
.HeaderBox {
  position: fixed;
  top: 25px;
  width: 80%;
  left: 320px;
}
.HeaderBoxAR {
  position: fixed;
  top: 25px;
  width: 80%;
  right: 320px;
}
.MiniHeaderBox {
  position: fixed;
  top: 25px;
  width: 90%;
  left: 110px;
}
.MiniHeaderBoxAR {
  position: fixed;
  top: 25px;
  width: 90%;
  right: 110px;
}
.mediaImage {
  width: 300px;
  height: 190px;
}
.HeaderBoxPaper {
  height: 130px;
  padding: 30px;
  background-color: #020a58 !important;
}
.SchoolManagmentWrapper {
  width: 100%;
  height: 580px;
  padding: 0px 5px;
}
.PageRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.pageTriangle {
  background: var(--unnamed-color-ffffff) 0 0 no-repeat padding-box;
  border: 2px solid #020a58;
  height: 11px;
  margin: 0 5px;
  opacity: 1;
  -webkit-transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
  transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
  width: 11px;
}
.focusedTriangle {
  background-color: #fcbf03;
}
.ladyHeader {
  width: 509px;
  height: 304px;
  position: fixed;
  right: 30px;
  top: -50px;
}
.ARladyHeader {
  width: 509px;
  height: 304px;
  position: fixed;
  left: 30px;
  top: -50px;
}
.borderBtn {
  border: 1px solid #148d9e !important;
  color: #148d9e !important;
}
.greyOval {
  top: 140px;
  background: #393939 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 5px 6px #0000006f, 0px 3px 6px #00000029;
  opacity: 0.71;
  cursor: pointer;
}
.uploadedImg {
  border-radius: 70%;
  width: 137px;
  height: 137px;
  position: absolute;
  top: 20px;
  z-index: 2000;
}

.LargeViewOnly {
  display: block !important;
}

.sidebar {
  top: 0px;
  left: 0px;
  bottom: 0px;
  position: fixed;
  background-image: url("../assets/svg/sideBarImg.svg");

  width: 250px;
  height: 100vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  box-shadow: 11px 0px 13px #15151540;
  /* padding: 20px; */
  overflow-y: none;
}
.mobileOnly {
  display: none;
}
.newSideBarClass {
  height: 100%;
  width: 282px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #020a58;
  overflow-x: hidden;
  box-shadow: 11px 0px 13px #15151540;
}

.MiniSideBarClass {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #020a58;
  overflow-x: hidden;
  box-shadow: 11px 0px 13px #15151540;
}
.rotatedLogo,
.rotatedLogoAR {
  position: absolute;
  top: 190px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotatedLogo {
  left: -70px;
}
.rotatedLogoAR {
  position: absolute;
  top: 190px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: -70px;
}
.selectedBtn_Mini {
  color: #fff !important;
  font: normal normal normal 25px Uniform-Medium;
}
.minSideBarBtn {
  color: #fff !important;
  cursor: pointer;
  display: block;
  display: flex;
  flex-direction: row;
  height: 45px;
  letter-spacing: 0;
  opacity: 1;
  position: relative;
  text-transform: none !important;
}
.minSideBarBtn {
  width: 45px;
}
.MinimizedIcons {
  margin-left: 10px;
  margin-top: 150px;
}
.MinimizedIconsAr {
  margin-right: 10px;
  margin-top: 150px;
}
.miniSideBarIcon {
  height: 26px;
  margin: 10px;
  width: 26px;
}
.ENdesktopView {
  margin-left: 300px;
}
.ARdesktopView {
  margin-right: 300px;
}
.ARSideBarClass {
  height: 100%;
  width: 282px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background: #020a58;
  overflow-x: hidden;
  box-shadow: 0px 11px 13px #15151540;
}

.MiniSideBarClassAR {
  height: 100%;
  width: 75px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background: #020a58;
  overflow-x: hidden;
  box-shadow: 0px 11px 13px #15151540;
}
.ARShowDateRange {
  position: absolute;
  top: 60px;
  left: 0px;
}
.ShowDateRange {
  position: absolute;
  top: 60px;
  right: 0px;
}
.HideDateRange {
  display: none;
}
.GradeRow {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  cursor: pointer;
  border-bottom: 1px solid #fcbf03;
}
.childInfoBox {
  height: 400px;
}
.rateRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
  font: normal normal normal 20px Uniform ;
}
.sButtonText {
  text-decoration: none !important;
  color: white !important;
}
.darkFont {
  color: #020a58 !important;
  z-index: 1000;
  font: normal normal normal 19px/24px Uniform !important;
}
.deviceBox {
  height: 260px;
}
.RedBackGround {
  background-color: #ff3b59;
  color: white;
}
.GreenBackGround {
  background-color: #1daf8e;
  color: white;
}
.AcademicWrap {
  margin-top: 50px;
  margin-left: 10px;
}
.sButton {
  color: white !important;
  border: 2px solid white !important;
  /* box-shadow: 0px 3px 21px #0e596129; */
  text-transform: none !important;
  font: normal normal normal 19px/24px Uniform !important;
  text-align: left;
  -webkit-justify-content: left !important;
  cursor: pointer;
  padding: 0px 10px;
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.sButtonIcon {
  font-size: 24px;
  -webkit-justify-content: right !important;
}
.TermDropDown {
  width: 250px;
  max-width: 250px;
  z-index: 1000;
}
.mainFlower {
  display: none;
}
.lowerLogo {
  display: none;
}
.w150 {
  width: 284px;
  height: 40px;
}
.roundedImage {
  /* box-shadow: 0px 3px 21px #0e596129; */
}
.resourceDescription {
  font: normal normal normal 12px Uniform;
  letter-spacing: 0px;
  color: #020a58;
}

.siteHelp {
  position: absolute;
  top: -20px;
  left: 5px;
}
.logoutImg {
  position: absolute;
  top: 10px;
  left: 15px;
}
.logoutText {
}
.menuSection {
  background-image: linear-gradient(#147f89, #0a4046);
}
.schoolManag {
  margin: 10px;
}
.schoolManag2 {
  padding: 10px 20px;

  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
.HyperLnk {
  text-decoration: underline;
  cursor: pointer;
  font: normal normal 700 15px Uniform !important;
}
.SidebarTop {
  /* background-image: linear-gradient(#147f89, #0a4046); */
  height: 200px;
}
.sidebarTopDiv {
  margin: 25px 25px 25px 25px;
  z-index: 150000;
}
.bottomText {
  font: normal normal normal 27px   Uniform;
  padding: 15px 0;
}
.footerBtn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 47px;
  width: 240px;
  color: white;
  margin-left: 20px;
}
.footerBtn:hover {
  color: #fcbf03;
}
.ActiveLang {
  color: #fcbf03;
}
.InActive {
  color: white;
}
.InActive:hover {
  color: #00adba;
}
.parentName {
  font: normal normal normal 32px/43px Uniform-Medium;
  color: #ffffff;
  text-shadow: 0px 0px 15px #ffffffe3;
  padding: 20px;
  position: absolute;
  top: 270px;
  left: 10px;
}
.SidebarIcon {
  height: 21px;
  margin-left: 10px;
  margin-right: 15px;
  margin-top: 15px;
  width: 21px;
}
body {
  margin: 0;
}
.nav-text {
  position: relative;
  display: inline;
  vertical-align: middle;
  width: 190px;
  padding: 7px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  height: 100%;
  overflow: auto;
}

li a {
  display: block;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}

li a.active {
  background-color: #04aa6d;
  color: white;
}

li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.SidebarIcon {
  width: 26px;
  height: 26px;
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 10px;
}
.infoIcon {
  width: 27px;
  height: 27px;
}
.UnselectedSidebarIcon {
  width: 26px;
  height: 26px;
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 10px;
}
.icon-bar {
  position: fixed;

  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.alignL {
  left: 340px;
}
.alignR {
  right: 370px;
}
.DisplayD_FlexRow {
  display: flex;
  flex-direction: row;
}
.semiColon {
  width: 30px;
  min-width: 30px;
  font: normal normal normal 30px/41px Uniform-Medium;
  letter-spacing: 0px;
  color: #020a58;
}
.displayRow {
  display: flex;
  flex-direction: row;
}
.jLeft {
  justify-content: flex-start !important;
}
.SchoolImg {
  width: 90px;
  height: 90px;
}
.mtop10 {
  margin-top: 10px;
}
.mtop20 {
  margin-top: 20px;
}
.mtop50 {
  margin-top: 50px;
}
.yellowLink {
  color: #fbbe2c;
  font: normal normal 600 15px/19px Uniform;
  letter-spacing: 0;
  padding: 5px;
  text-decoration: underline;
  border: none;
  background-color: white;
  cursor: pointer;
}
.nameHover{
  margin: 0px 5px
}
.yellowLinkArrrow {
  height: 10px;
  padding-left: 5px;
  width: 15px;
}
.yellowLinkTransform{
  transform: rotate(180deg);
}
.centerScreenO {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 580px;
  top: 300px;
  left: 320px;
  width: 80%;
  padding-right: 60px;
}
.MinicenterScreenO {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 580px;
  top: 300px;
  left: 110px;
  width: 90%;
  padding-right: 60px;
}
.attendanceBtn {
  width: 261px;
  border: 2px solid #fbbe2c;
  color: #fbbe2c;
  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
  margin-top: 5px;
}
.attendanceBtn:hover {
  border-bottom: 4px solid #fbbe2c;
  border-left: 4px solid #fbbe2c;
}
.MiniARcenterScreenO {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
  top: 300px;
  right: 110px;
  width: 90%;
  padding-left: 80px;
}
.ARcenterScreenO {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
  top: 300px;
  right: 320px;
  width: 80%;
  padding-left: 60px;
}
.SidebarIconsUL {
  margin-top: 100px;
  margin-left: 0px;
}

.extraChildBtn {
  width: 80px !important;
  height: 20px !important;
  margin: 5px;
  padding: 10px 20px !important;
  border-radius: 8px;
}
.SchoolImage {
  /* padding-right: 20px; */
}
.SelectedExtraChild {
  color: #00b6bc !important;
  border: 2px solid #00b6bc !important;
}
.centerScreen {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 680px;
  top: 275px;
  left: 390px;
  width: 75%;
  padding-right: 50px;
  padding-left: 10px;
}

.ARcenterScreen {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 680px;
  top: 275px;
  right: 390px;
  width: 75%;
  padding-right: 50px;
  padding-left: 10px;
}

.DashboardBox {
  margin-left: 5px;
  margin-top: 20px;
}
tr.spaceUnder > td {
  /* padding-bottom: 0.5em; */
  padding-top: 0.5em;
}
.AttendanceTablePadding {
  padding: 50px 0px;
  padding-bottom: 1em;
  position: relative;
}

.AbsentRateBox {
  height: 230px;
}
.filterBox {
  position: absolute;
  right: 0px;
  z-index: 1000;
}
.AcademicPaperRecord {
  margin: 10px 20px 50px 20px;
  padding-bottom: 50px;
}
.ColorHeader {
  background-color: #00adba;
  color: white;
  padding: 5px 10px;
}
.spaceBetweenRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.p10 {
  padding: 10px;
}
.tableText {
  font: normal normal normal 24px Uniform;
  letter-spacing: 0px;
  color: #020a58;
  opacity: 1;
  text-align: center;
}
.attendanceIcon {
  font-size: 25px;
}
.resourceImage {
  width: 390px;
  height: 270px;
}
.roundedDiv {
  width: 133px;
  height: 24px;
  border-radius: 10px;
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000036, 0px 0px 21px #0e596129;
  margin: 10px;
  opacity: 1;
  color: white;
  text-align: center;
}
.subjectRow {
  width: 700px;
  padding-top: 10px;
  color: #020a58 !important;
  font: normal normal normal 20px Uniform_Static;
}
.AbsentBox {
  border: 4px solid #1daf8e;
}
.AbsentRateBox {
  height: 78px;
}
.NoImageToDisplay {
  width: 500px;
  height: 450px;
}
.mapText {
  font: normal normal normal 20px Uniform;
  letter-spacing: 0px;
  color: #020a58;
}
.roundedlegend {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-shadow: inset 0px 3px 6px #00000036, 0px 0px 21px #0e596129;
  margin: 10px;
  opacity: 1;
}
.legendRow {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-align: center;
  vertical-align: center;
  margin-right: 10px;
}
.WithBorder {
  border-right: 1px dashed #020a58;
  padding-right: 10px;
}
.greyColor {
  color: #020a58 !important;

  font: normal normal normal 20px Uniform;
}

.emtpy {
  background-color: rgb(197, 197, 197);
  border-color: rgb(151, 151, 151);
}
.Below {
  background-color: red;
  border-color: rgb(151, 151, 151);
}
.Emerging {
  background-color: #FFCCCC;
  border-color: rgb(151, 151, 151);
}
.Approaching {
  background-color: #FFC000;
  border-color: rgb(151, 151, 151);
}

.Meets {
  background-color: #92D050;
  border-color: rgb(151, 151, 151);
}
.Exceeds {
  background-color: #9DC3E6;
  border-color: rgb(151, 151, 151);
}
.NavyBorder {
  border: 4px solid #020a58;
}
.OtherBlueBorder {
  border: 4px solid #00adba;
}
.GreenBorder {
  border: 4px solid #1daf8e;
}
.studentInfoBox {
  box-shadow: 0px 3px 6px #00000029;
}
.roundedW {
  color: #020a58;
  width: 133px;
  margin: 10px;
  opacity: 1;
  text-align: center;
}
.width30 {
  width: 32%;
}
.userImageStudentInfo {
  width: 120px;
  height: 140px;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 35px;
}
.roww {
  margin: 10px;
  font-size: small;
  cursor: pointer;
}
.arabicDontChange {
  font-family: Uniform_Static !important;
}
.icon-bar a {
  display: block;
  text-align: center;

  transition: all 0.3s ease;

  font-size: 20px;
}
.selectTry {
  z-index: 1000;
}
/* .icon-bar a:hover {
  box-shadow: 0px 0px 45px #106a73;
} */
.stickyIconImage {
  width: 30px;
  height: 30px;
}

.NStickyIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
.HoverbackgroundBox {
  background: #1daf8e !important;
}
.backgroundBox {
  height: 45px;
  text-align: center;
  transform: rotate(45deg);
  width: 45px;
  position: absolute;
  top: 0px;
  left: 3px;
  z-index: 500;
}
.OpenMenu {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  
}
.InactiveBox {
  background: #00adba;
}
.ActivebackgroundBox {
  background: #fcbf03;
}

.ActivestickyIconImage {
  width: 45px;
  height: 45px;
}
.stickyIcon {
  margin: 20px;
  width: 20px;
  height: 20px;
  padding: 3px;
  position: relative;
}
.inactive {
  left: 15px;
  opacity: 0.4;
}
.inactiveR {
  right: 15px;
  opacity: 0.4;
}
.stickyIcon:hover {
  color: #1cb7c5;
  border: 3px solid #1cb7c5;
  border-radius: 50%;
  box-shadow: 0px 0px 45px #106a73;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.verticalOnly {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}
.activeStickyIcon {
  text-align: center;

  width: 50px;
  height: 50px;
}
.content {
  margin-left: 75px;
  font-size: 30px;
}
.fixedParentHeader {
  position: fixed;
  margin-top: 15px;
  margin-left: 0px;
  width: 75%;
  height: 200px;
}
.inMap {
  margin-top: 40px;
}
.OuterInfoBox {
}
.NavyBackGround {
  background-color: #020a58 !important;
  color: white;
}
.OtherBlueBackGround {
  background-color: #00adba !important;
  color: white;
}
.SectionTitle {
  padding: 7px 10px;
  font: normal normal normal 27px/41px Uniform Bold;
}
.ExplanationTitle {
  font: normal normal bold 20px Uniform;
}
.BottomSectionTitle {
  font: normal normal normal 27px Uniform Bold;
  padding: 5px 10px;
}
.SecTitle {
  font: normal normal normal 27px/41px Uniform Bold;
  padding-left: 10px;
  color: white;
}
.SchoolProfileTitle {
  font: normal normal normal 27px/41px Uniform Bold;
  padding: 3px 10px;
}
.p20 {
  padding: 20px;
}
.legendText {
  padding: 10px 0px;
  width: 150px;
  color: #020a58;
  font: normal normal normal 17px Uniform;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 368px !important;
  height: 231px !important;
}
.compTitleD {
  /* text-align: left; */
  font: normal normal normal 35px/41px Uniform Bold;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: #020a58;
  text-transform: capitalize;

  opacity: 1;
}
.resourceBox:hover {
}
.resourceBox {
  /* UI Properties */
  width: 368px;
  height: 500px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  display: flex;
  flex-direction: column;
}
.resourceInnerWrap {
  padding: 20px;
}
.resourceTopRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.resourceTitle {
  color: #020a58;
  font: normal normal normal 16px Uniform;
  letter-spacing: -0.2px;
  text-align: center;
}
.resourceType {
  color: #020a58;
  font: normal normal normal 28px Uniform;
  letter-spacing: -0.2px;
  text-align: center;
}
.m5 {
  margin: 5px;
}
.schoolIcon {
  margin: 5px 10px;
  width: 20px;
  height: 20px;
}
.attedanceBox {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding-left: 10px;
}
.resourceDate {
  color: #fcbe2c;
  font: normal normal 600 12px/26px Uniform;
  letter-spacing: 0;
}
.resourceResourceType {
  color: #020a58;
  font: normal normal 600 14px/26px Uniform;
  letter-spacing: 0;
}
.studentInfoRow {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 50px;
  padding-bottom: 20px;
  height: 200px;
}
.mtop5 {
  margin-top: 5px;
}
.rotateIcon {
  transform: rotate(180deg);
}
.compTitle {
  font: normal normal normal 27px/41px Uniform Bold;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: white;
  text-transform: capitalize;
  opacity: 1;
  padding-left: 20px;
}
.mobileColumn {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}
.absesnes {
  color: #a7a7a7;
  text-align: left;
  font-size: 14px;
  font: normal normal normal 17px Uniform_Static;
}
.AttendancePaper {
  margin: 10px 0px;
}
.WarningText {
  color: #ff3b59;
  font: normal normal normal 20px Uniform;
  letter-spacing: 0;
  text-align: center;
}
.FooterView {
  background-color: #020a58;
  bottom: 0px;

  color: #fff;
  padding: 10px 0;
  position: absolute;
  width: 100%;
}
.absoluteTopFooter{
  position: absolute;
  top:10px;
  right:50px;
  width: 120px;
  height: 150px;;
}
.schoolEdu{
  position: absolute;
  top:20px;
  left:50px;
}

.absoluteBottomFooter{
  position: absolute;
  top:110px;
  right:120px;
}
.FooterWrap{
  position: relative;
  height: 100%;
  width: 100%;
}
.LargeFooterView{
  background-color: #020a58;

  bottom: 0px;
  left:0px;
  height: 280px;
  color: #fff;
  position: sticky;
  position: fixed;
  width: 100%;
}
.FooterLink {
  color: #fff;
  text-decoration: none !important;
}
.arabicSymbolImg {
  position: absolute;
  right: 20px;
  top: 25px;
}
.arabicSymbolImgAR {
  position: absolute;
  left: 20px;
  top: 25px;
}

.bottomArabicSymbolImg {
  position: absolute;
  right: 120px;
  top: 150px;
}
.bottomArabicSymbolImgAR {
  position: absolute;
  left: 120px;
  top: 150px;
}
.yellowTriangle {
  margin: 0px 20px;
  width: 16px;
  height: 16px;
  transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
  background: #fcbf2c 0% 0% no-repeat padding-box;
}
.ARbottomFooterRow,
.bottomFooterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.FooterIcon {
  margin: 0 10px;
}

.bottomFooter{
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.FooterLink,
.smallFooterText {
  font: normal normal normal 12px Uniform Light_Static;
}
.LoginFooterRow{
  padding:0px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}  

.bottomFooterRow {
  padding-left: 300px;
  padding-right: 20px;
}
.ARbottomFooterRow {
  padding-right: 300px;
  padding-left: 20px;
}
.welcomeBack {
  text-align: left;
  font: normal normal normal 53px Uniform-Medium;
  letter-spacing: 0px;
  color: white;
  text-transform: capitalize;
  opacity: 1;
}
.messageText {
  text-align: left;
  font: normal normal normal 25px/34px Uniform;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  padding-left: 10px;
}
.studentEmail {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #020a58;
  opacity: 1;
}
.roww {
  font: normal normal normal 20px/25px Uniform;
  letter-spacing: 0px;
  color: #020a58;
  opacity: 1;
  display: flex;
  flex-direction: row;
}
.DateRow {
  padding: 10px;
  text-align: left;
  font: normal normal normal 25px/30px Uniform_Static;
  letter-spacing: 0px;
  color: #020a58;
  opacity: 1;
  display: flex;
  flex-direction: row;
}
.LabelT {
  width: 110px;
  min-width: 110px;
}
.ARLabelT {
  width: 130px;
  min-width: 130px;
}
.DeviceT {
  width: 190px;
  min-width: 190px;
}
.TermDropDown {
  font: normal normal normal 19px/24px Uniform !important;
  border: none !important;
}
.textDate {
  width: 110px;
}
.LabelColin {
  width: 15px;
  min-width: 15px;
}
.MobilePosition {
  position: absolute;
  left: 30px;
}
.totalAbs {
  color: #020a58;
}
.percentage {
  color: #1daf8e;
}
.absenceNumber {
  text-align: left;
  letter-spacing: 0px;
  color: #fcbf03;
  text-transform: capitalize;
  opacity: 1;
  font: normal normal normal 30px Uniform-Medium_Static;
  font-weight: bold;
  padding: 5px 3px;
}
.rateText {
  font: normal normal normal 60px/69px Uniform-Medium_Static;
  letter-spacing: 0px;
  color: #0ab795;
  text-transform: capitalize;
  padding: 0px !important;
}
.langBTN {
  color: white;
  cursor: pointer;
}
.excusedUnexcused {
  color: #a7a7a7;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font: normal normal normal 14px Uniform;
}
.MapTitle {
  font: normal normal normal 27px Uniform;
  letter-spacing: 0px;
  color: #020a58;
  opacity:attedanceBox 1;
  padding: 50px 0px;
}
.DSectionBox {
  margin-top: 150px;
  margin-left: 10px;
}
.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}
.SchoolName {
  color: white;
  font-size: 25px;
  text-transform: capitalize !important;
  padding-left: 10px;
}
.sideBarFooter {
  position: absolute;
  bottom: 50px;
  left: 20px;
}
.langFooter {
  position: absolute;
  bottom: 10px;
  left: 30px;
}
.langFooterAR {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.sideBarTutorial {
  position: absolute;
  bottom: 130px;
  left: 20px;
}
.attendancePaper {
  margin: 10px;
  padding: 30px;
}
.MapDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: space-between;
  padding:0px 40px;
}
.mapLeg {
  padding-top: 150px;
}
.tourPopUp {
  width: 500px !important ;
  /* height: 150px; */
  max-height: 500px !important ;
  max-width: 500px !important ;
  border-radius: 8px !important ;
}
.tutTitle {
  font: normal normal normal 20px Uniform-Medium;

  color: #020a58;
  margin-bottom: 20px;
}
.studentMap {
  width: 28px;
  height: 28px;
  background: #fbc434 0% 0% no-repeat padding-box;
  /* box-shadow: inset 0px 3px 6px #00000036, 0px 3px 21px #0e596129; */
  border-radius: 8px;
  margin: 10px;
}
.innerMapPaper {
  margin-top: 40px;
  padding: 20px;
  padding-top: 40px;
}
.maskClasss {
  /* border: 5px solid red; */
}
.BenchMark {
  width: 28px;
  height: 28px;
  background: #020a58 0% 0% no-repeat padding-box;
  /* box-shadow: inset 0px 3px 6px #00000036, 0px 3px 21px #0e596129; */
  border-radius: 8px;
  opacity: 1;
  margin: 10px;
}
.lengendText {
  padding: 10px;
  font: normal normal normal 20px Uniform;

  color: #020a58;
}
#changePicDiv {
  display: none;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
#userImage:hover + #changePicDiv {
  display: block;
}
.logo {
  margin-top: 5px;
  margin-right: 10px;
}
.sideBarButton {
  font: normal normal normal 24px Uniform-Medium;
  letter-spacing: 0px;
  color: white !important;
  text-transform: none !important;
  opacity: 1;
  width: 245px;
  height: 51px;
  cursor: pointer;
  display: block;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 20px;
}
.w100 {
  width: 100px;
}
.sideBarText {
  margin-top: 15px;
}

.navBarWrapper {
  padding-top: 20px;
}
.sideBarButtonHover {
  color: #fcbf03 !important;
  /* font: normal normal normal 23px Euphemia UCAS; */
}
.selectedBtn {
  /* font: normal normal normal 25px Uniform-Medium; */
  color: #00adba !important;
}
.ParentDetails {
  background-color: #219aa5;
  color: white;
  border-radius: 21px;
  width: 300px;
  height: 50px;
  padding: 20px;
}
.informationIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 120px;
}
.parentInformation {
  /* background-image: url("../../src/assets/svg/InfoPopUp.png"); */
  z-index: 1000000;
  background-color: #00adba;
  color: white;
  position: fixed;
  width: 700px;
  height: 200px;
  padding: 20px;
  border-radius: 20px;
  font-size: 14px;
  top: 290px;
}
.parentEN {
  left: 5px;
}
.parentAR {
  right: 5px;
}
.parentWrap {
  margin-left: 10px;
  margin-top: 20px;
}
.aboutTheParent {
  font: normal normal normal 24px Uniform Bold;
  margin-bottom: 25px;
}
.roundedBtnX {
  background-color: #e3e3e3 !important;
  border-radius: 50% !important;
  padding: 7px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 2500;
}
.parentHoverInfo {
  font: normal normal normal 20px Uniform-Medium;
}
.inactiveRounded {
  border: 2px solid #020a58 !important;
  color: #020a58 !important;
  width: 15px !important;
  height: 15px !important;
  min-width: 15px !important;
  font-size: 12px !important;
}
.inactiveRounded:hover {
  border: 2px solid #020a58 !important;
  color: #020a58 !important;
  width: 17px !important;
  height: 17px !important;
  min-width: 17px !important;
  font-size: 14px !important;
}
.activeRounded {
  color: #00b6bc !important;
  border: 3px solid #00b6bc !important;
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  font-size: 14px !important;
  font-weight: bold;
}
.mediaHeader {
  text-align: left;
  font: normal normal normal 20px Uniform;
  letter-spacing: 0px;
  color: #020a58;
  opacity: 1;
}
.ResourcesRow {
  display: flex;
  flex-direction: column;
  color: #020a58;
  padding: 10px;
}
.MapTitle {
  color: #020a58;
  font: 25px;
}
.attendanceTable th {
  text-align: center;
  font: normal normal normal 25px Uniform;
  letter-spacing: 0px;
  color: #020a58;
}
.attendanceTable > thead {
  border-bottom: 2px solid #020a58;
}
.MChart {
  width: 450px;
  height: 350px;
}
.mapBox {
  width: 830px;
  height: 540px;
  border: 2px solid #eee;
  display: inline-block;
  overflow: hidden;
}
.resourceNumber {
  font: normal normal normal 71px/87px Uniform;
  color: #020a58;
  position: relative;
}
.MapPaper {
  margin-top: 0px;
  padding: 50px 30px;
}
.imageResource {
  margin: 50px 200px;
}
.resourceNumber {
  font: normal normal normal 50px/68px Uniform-Medium;
  width: 50px;
  min-width: 50px;
}
.title {
  font: normal normal normal 30px/41px Uniform-Medium;
  bottom: 5px;
  left: 50px;
  position: absolute;
}
.Titledescription {
  font: normal normal normal 22px/27px Uniform;
  bottom: 10px;
  left: 150px;
  position: absolute;
}
.pdfInResource {
  width: 120px;
  height: 90px;
  margin-top: 30px;
}
.resourceHeader {
  font: normal normal normal 26px/35px Uniform-Medium;
  letter-spacing: 0px;
  color: white;
  padding: 5px;
}
.description {
  font: normal normal normal 20px/27px Uniform;
  letter-spacing: 0px;
  color: #020a58;
}
.MuiPaper-root {
  box-shadow: 0px 3px 21px #0e596129 !important;
  background-color: white !important;
  border-radius: 8px !important;
}
.AlignLeft {
  text-align: left !important;
}
.AlignRight {
  text-align: right !important;
}
.StudentInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 7px;
}
.studentInfoColumn {
  display: flex;
  flex-direction: column;
}
.GradeExplanation {
  padding: 20px;
  width: 700px;
  /* height: 220px; */
  position: absolute;

  z-index: 2000;
  color: #020a58 !important;
  font-size: 15px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029, 0px 0px 12px #0e596158;
  border-radius: 8px;
  opacity: 1;
}
.TeacherDetailPopUp {
  padding: 20px;
  width: 500px;
  height: 200px;
  position: absolute;

  z-index: 1000;
  color: #020a58 !important;
  font-size: 15px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029, 0px 0px 12px #0e596158;
  border-radius: 8px;
  opacity: 1;
}

.EnAlign {
  right: 130px;
}
.ARAlign {
  left: 130px;
}

.errorText {
  color: red;
}
.TeacherPopUp {
  width: 120px;
  height: 100px;
}
