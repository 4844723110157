@media only screen and (max-width: 1400px) and (min-width: 1000px) {
  .ladyHeader {
    width: 268px !important;
    height: 186px !important;
    top: -24px !important;
  }
  .HeaderBox {
    top: 30px !important;
    margin-left: 5px !important;
  }
  .HeaderBoxPaper {
    height: 100px !important;
    padding: 10px !important;
  }
  .navBarWrapper {
    padding-top: 5px !important;
  }
  .selectedBtn {
    font: normal normal normal 15px/20px Uniform !important;
  }
  .welcomeBack {
    font: normal normal normal 21px/26px Uniform !important;
  }
  .messageText {
    font: normal normal normal 12px/15px Uniform !important;
  }
  .sideBarButton {
    font: normal normal normal 11px/15px Uniform !important;
    margin: 15px 0px !important;
  }
  .LabelT {
    width: 35px !important;
    min-width: 35px !important;
  }
  .LabelColin {
    width: 7px;
    min-width: 7px;
  }
  .excusedUnexcused {
    font: normal normal normal 9px/11px Uniform !important;
  }
  .rateText {
    font: normal normal normal 28px/32px Uniform !important;
  }
  .absesnes {
    font: normal normal normal 9px/11px Uniform !important;
  }
  .absenceNumber {
    font: normal normal normal 64px/74px Uniform !important;
  }
  .DashboardBox {
    margin-top: 0px !important;
  }
  .subjectRow {
    font: normal normal normal 12px/15px Uniform !important;
    width: 200px !important;
    padding-top: 5px !important;
  }
  .infoIcon {
    width: 14px !important;
    height: 13px !important;
  }
  .roundedDiv {
    width: 69px !important;
    height: 13px !important;
  }
  .centerScreen {
    top: 200px !important;
    left: 310px !important;
  }
  .DateRow {
    font: normal normal normal 12px/15px Uniform !important;
  }
  .BottomSectionTitle {
    font: normal normal bold 14px/17px Uniform !important;
  }
  .mapText {
    font: normal normal normal 12px/15px Uniform !important;
  }
  .MAP_Legend {
    padding: 10px !important;
    padding-left: 75px !important;
  }
  .roundedlegend {
    width: 15px !important;
    height: 15px !important;
  }
  .legendText {
    font: normal normal normal 9px/11px Uniform !important;
  }
  .icon-bar {
    left: 235px !important;
  }
  .compTitleD {
    font: normal normal normal 27px/32px Uniform !important;
  }
  .mapBox {
    width: 350px !important;
    max-width: 350px !important;
  }
  .compTitle {
    font: normal normal bold 18px/22px Uniform !important;
  }
  .resourceNumber {
    display: none;
  }
  .imageResource {
    width: 140px !important;
    height: 134px !important;
    min-width: 140px !important;
  }
  .ActivestickyIconImage {
    width: 30px !important;
    height: 30px !important;
  }
  .inactive {
    left: 7px !important;
  }
  .activeStickyIcon {
    width: 35px !important;
    height: 35px !important;
  }
  .resourceHeader {
    font: normal normal bold 17px/21px Uniform !important;
  }
  .sideBarFooter {
    bottom: 25px !important;
  }
  .resourceImage {
    width: 140px !important;
    height: 134px !important;
    min-width: 140px !important;
  }
  .description {
    font: normal normal normal 14px/17px Uniform !important;
  }
  .attendanceTable th {
    font: normal normal normal 16px/20px Uniform !important;
  }
  .DashboardResourceImage {
    width: 140px !important;
    height: 134px !important;
  }
  .SchoolProfileTitle {
    font: normal normal bold 16px/20px Uniform !important;
  }
  .attendanceIcon {
    font-size: 20px !important;
  }
  .tableText {
    font: normal normal normal 15px/19px Uniform !important;
  }
  .SchoolImg {
    width: 81px !important;
    height: 105px !important;
  }
  .greyColor {
    font: normal normal normal 12px/15px Uniform !important;
  }
  .centerScreenO {
    top: 180px !important;
    left: 270px !important;
  }
  .BenchMark {
    width: 15px !important;
    height: 15px !important;
    margin: 5px !important;
  }
  .mapLeg {
    padding-top: 75px !important;
  }
  .studentMap {
    width: 15px !important;
    height: 15px !important;
    margin: 5px !important;
  }
  .lengendText {
    font: normal normal normal 8px/10px Uniform !important;
    padding: 3px !important;
  }
  .sButtonText {
    font: normal normal normal 12px/15px Uniform !important;
  }
  .w150 {
    width: 170px !important;
  }
  .sButtonIcon {
    width: 20px !important;
    height: 20px !important;
  }
  .SectionTitle {
    font: normal normal bold 16px/20px Uniform !important;
  }
  .SecTitle {
    font: normal normal bold 16px/20px Uniform !important;
  }
  .roww {
    font: normal normal normal 12px/15px Uniform !important;
  }
}
