.mobileHeader {
  /* height: 87px; */

  box-shadow: 0px 4px 25px #000000;
  opacity: 1;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: hidden;
}
.MparentName {
  font: normal normal normal 19px/26px Uniform-Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-top: 10px;
  padding-left: 10px;
}
.MobileTopBackground {
  position: relative;
  background-color: #020a58 !important;
  /* background-image: url("../../src/assets/svg/mobile/DashbordHeadBack.png"); */
}
.MobileTopBackgroundMini {
  background-color: #020a58 !important;
  height: 38px;
  padding-top: 10px;
  /* background-image: url("../../src/assets/svg/mobile/DashbordHeadBack.png"); */
}
.displayRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mobileTop {
  /* border-bottom: 1px solid rgb(192, 192, 192); */
}
.hamburgerMenu {
  padding: 14px;
}
.MInactiveMenu {
  display: none;
  /* width: 36px;
  height: 36px;
  background: #0d6169 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 6px #219aa5, 0px 0px 5px #000000;
  border-radius: 21px;
  opacity: 1; */
}

.MActiveMenu {
  width: 180px;
  height: 26px;
  max-height: 26px;
  background: #1daf8e 0% 0% no-repeat padding-box;
  border: 2px solid #1daf8e;
  border-radius: 21px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  margin-left: 0px;
}
.MIconMenu {
  width: 30px;
  height: 30px;
}
.absolutePostioning {
  position: absolute;
  left: 10px;
}
.MobileIcon {
  width: 35px;
  height: 35px;
  margin-top: -5px;
}
.MActiveIcon {
  width: 30px;
  height: 30px;

  opacity: 1;

  margin-left: -5px;
  text-align: center;
}
.ActiveText {
  text-align: left;
  font: normal normal normal 14px Uniform;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 0px 15px #000000;
  text-transform: capitalize;
  opacity: 1;
  padding: 5px;
  padding-left: 10px;
}
.InactiveDashboardMenu {
  text-align: center;
}
.InActiveText {
  display: none;
}

.menuIcons {
  padding: 0px 10px 10px 10px;
}

@media only screen and (max-width: 920px) and (min-width: 360px) {
  * {
    padding: 0px;
    margin: 0px;
    font-family: Uniform;

    /* font-size: 14px; */
  }

  .SidebarIconsUL {
    margin-top: 50px !important;
  }
  body {
    /* background-image: url("../../src/assets/svg/mobile/background.png"); */
  }
  .MobileMenuu {
  }
  .MapChartRow {
    flex-direction: column;
    position: relative;
  }
  .SchoolImage {
    padding-right: 10px;
    width: 30px;
  }
  .MobileCenterImage {
    text-align: center;
  }
  .schoolManag {
    margin: 5px;
    padding: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .schoolManag2 {
    padding: 10px;

    display: flex;
    flex-direction: column;
    vertical-align: middle;
  }

  .messageMobile {
    display: none;
  }
  .MobileAbsents {
    font: normal normal bold 13px/16px Uniform !important;
  }
  .AbsentBox {
    height: 80px;
    width: 95%;
  }
  .AbsentRateBox {
    height: 80px;
    width: 45%;
  }
  .attedanceBox {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;
  }
  .smallMobileColumn {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
  }
  .studentInfoBox {
    padding-bottom: 10px;
  }

  .sideBarText {
    margin-top: 0px !important;
  }
  .excusedUnexcused {
    padding-top: 5px;
    font: normal normal normal 9px Uniform !important;
    letter-spacing: 0px;
    color: #a7a7a7;
  }
  .NoImageToDisplay {
    width: 250px !important;
    height: 250px !important;
  }
  .DeviceT {
    width: 150px !important;
    min-width: 150px !important;
  }
  .TermDropDown {
    width: 155px;
    max-width: 155px;
    z-index: 1000;
    font-size: 10px !important;
    font: normal normal normal 10px Uniform !important;
  }
  .SideBarRight {
    background: transparent
      linear-gradient(180deg, #020a58 1%, #999898 25%, white 50%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    height: 100%;
    display: block !important;
    position: absolute;
    right: 0px;
    top: 0px;
    width: calc(100% - 210px);
  }
  .alignRight {
    text-align: right;
    justify-content: right;
  }
  .sideBarButton {
    width: 167px !important;
    height: 31px !important;
    padding: 5px !important;
  }
  .footerBtn {
    height: 20px !important;
    padding: 5px !important;
    width: 150px !important;
  }
  .sideBarTutorial {
    position: absolute;
    bottom: 90px;
    left: 20px;
  }
  .bottomText {
    font: normal normal normal 13px/18px Uniform-Medium !important;
    letter-spacing: 0px;
    color: white !important;
    margin-left: 10px !important;
    padding-top: 0px !important;
  }
  .siteHelp {
    width: 39px;
    height: 40px;
  }
  .logoutImg {
    width: 39px;
    height: 20px;
  }
  .mobileParentName {
    text-align: left;
    font: normal normal normal 18px/22px Uniform;
    letter-spacing: 0px;
    color: white;
    text-shadow: 0px 0px 15px #ffffffe3;
  }
  .LargeViewOnly {
    display: none !important;
  }
  .ParentInfo {
    margin-top: 150px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font: normal normal normal 12px Uniform;
  }
  .mobileOnly {
    display: block !important;
  }
  .mobileTopLogo {
    display: none !important;
  }
  .lowerLogo {
    display: block;
    position: absolute;
    bottom: 70px;
    left: 0px;
  }
  .mainFlower {
    display: block;
    position: absolute;
    top: 70px;
    left: 20px;
  }
  .mobileSideWrapper {
    display: flex;
    flex-direction: row;
  }
  .SidebarTop {
    background-image: none !important;
  }
  .AttendanceRowAndRate {
    display: flex;
    flex-direction: row;
  }
  .MobilePosition {
    position: absolute;
    top: -40px;
    left: -30px;
  }
  .parentName {
    position: absolute;
    top: 5px;
    left: 30px;
    font: normal normal normal 19px/26px Uniform-Medium;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .hamburgerMenu {
    margin: 0px 10px;
  }
  .newSideBarClass {
    /* background: transparent
      linear-gradient(
        181deg,
        #0d7179 0%,
        #1db1be 16%,
        #147f89 35%,
        #06272b 100%
      )
      0% 0% no-repeat padding-box !important; */
    /* background-image: url("../assets/svg/mobile/mobilBackground1.png") !important; */
    background-image: none !important;
    background-color: #020a58 !important;
    box-shadow: inset 0px 0px 8px #000000f4 !important;
    border-radius: 0px 16px 16px 0px !important;
    opacity: 1;
    width: 220px !important;
  }
  .ARSideBarClass {
    background-image: none !important;
    background-color: #020a58 !important;
    box-shadow: inset 0px 0px 8px #000000f4 !important;
    border-radius: 0px 16px 16px 0px !important;
    opacity: 1;
    width: 220px !important;
  }
  .LeftLogin{
    width: 85%;
    margin-left: 15px;
  }
  .loginpage {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .text-right {
    text-align: right;
  }
  .loginCenter{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sideBarFooter {
    bottom: 40px !important;
  }
  .TeacherPopUp {
    width: 50px !important;
    height: 50px;
  }
  .TeacherDetailPopUp {
    padding: 10px !important;
    width: 90% !important;
    height: 200px !important;
    position: absolute;
    right: 10px !important;
    font-size: 12px !important;
  }
  .navBarWrapper {
    padding-top: 10px !important;
  }
  .SidebarIcon {
    margin-top: 0px !important;
  }
  .selectedBtn > .sideBarText {
    color: white !important;
  }
  .UnselectedSidebarIcon {
    opacity: 1;
    width: 22px;
    height: 23px;
    padding: 3px;
    margin-top: 0px !important;
  }
  .sideBarText {
    font: normal normal normal 14px Uniform;
    letter-spacing: 0px;
    color: white;
    text-transform: capitalize;
    padding-top: 5px;
  }
  .mediaImage {
    width: 250px;
    height: 190px;
  }
  .resourceBox {
    /* UI Properties */
    width: 90%;
    height: 500px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .centerScreenO {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80%;

    top: 200px !important;
    left: 3px;
    right: 3px;
    width: 100%;
  }
  .MobileOnlyColumn {
    flex-direction: column !important;
  }
  .ARcenterScreenO {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70%;
    padding-left: 0px !important;
    top: 170px !important;
    left: 3px;
    right: 3px;
    width: 100%;
  }
  .attendancePaper {
    margin: 10px;
  }
  .MobileCenterScreen {
    position: absolute;
    top: 220px !important;
    padding-left: 10px;
    right: 3px;
    left: 3px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 70%;
  }
  .DisplayD_FlexRow {
    display: flex;
    flex-direction: column;
  }
  .greyColor {
    font: normal normal normal 12px Uniform !important;
  }
  .AcademicWrap {
    margin-top: 20px;
    margin-left: 5px;
  }
  .HyperLnk {
    text-decoration: underline;
    cursor: pointer;
    font: normal normal normal 12px Uniform !important;
  }
  .AttendanceTablePadding {
    padding: 20px 0px 0px 0px;
    padding-bottom: 1em;
  }
  .attendanceTable th {
    border-collapse: collapse;
    text-align: center;
    font: normal normal normal 13px Uniform;
    color: #000000;
  }
  .DatePickk {
    font-size: 11px !important;
  }
  .SchoolManagmentWrapper {
    padding-left: 0px !important;
    height: auto !important;
  }
  .tableText {
    font: normal normal normal 11px/14px Uniform;
    letter-spacing: 0px;
    color: #020a58;
    opacity: 1;
    text-align: center;
  }
  .attendanceIcon {
    font-size: 15px;
  }
  .SchoolImg {
    width: 40px !important;
    height: 40px !important;
  }
  .SchoolProfileTitle {
    font: normal normal bold 14px Uniform;
  }
  .compTitle {
    font: normal normal normal 20px/27px Uniform-Medium !important;
    letter-spacing: 0px;
    color: white;
  }
  .SchoolName {
    font: normal normal normal 20px/27px Uniform-Medium !important;
  }
  .loginPagePaper {
    padding: 30px;
    width: 70%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .HeaderBox {
    position: fixed;
    top: 70px !important;
    width: 100% !important;
    left: 0px !important;
  }
  .HeaderBoxAR {
    position: fixed;
    top: 70px !important;
    width: 100%;
    right: 0px !important;
    left: 0px !important;
  }
  .HeaderBoxMovedDown {
    position: fixed;
    top: 120px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .MapTitle {
    font: normal normal normal 21px/26px Uniform;
    padding: 10px 20px !important;
  }
  .SectionTitle {
    font: normal normal normal 15px/20px Uniform-Medium !important;
  }
  .BottomSectionTitle {
  }
  .attendanceRateBox {
    margin-top: 10px;
    margin-left: 10px;
  }
  .sButtonIcon {
    /* width: 11px !important;
    height: 11px !important; */
    font-size: 14px;
  }
  .sButtonText {
    font: normal normal normal 12px Uniform !important;
  }
  .attendanceBtn {
    width: 250px;
    border: 2px solid #fbbe2c;
    color: #fbbe2c;
    text-align: center;
    padding: 5px 0px;
    cursor: pointer;
    margin-top: 3px;
  }


  .mobileColumn {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
  }
  .HideMobile {
    display: none !important;
  }
  .welcomeBack {
    font: normal normal normal 16px/20px Uniform;
  }
  .messageText {
    font: normal normal normal 14px Uniform;
  }
  .HeaderBoxPaper {
    padding: 10px;
    height: 70px;
  }
  .sidebar {
    width: 80%;
  }
  .messageText {
    padding-left: 0px;
  }
  .mobileWrap {
    width: 100%;
  }
  .MapPaper {
    margin-top: 10px;
    padding: 20px 5px 5px 5px;
  }
  .icon-bar {
    left: 0px;
  }
  .stickyIcon {
    margin: 5px;
  }

  .inactive {
    left: 5px;
  }

  .roww {
    font: normal normal normal 14px/17px Uniform;
  }
  .rateText {
    padding: 0px !important;
    padding-top: 20px;
    font: normal normal normal 33px Uniform-Medium;
  }
  .BottomSectionTitle {
    padding-top: 10px;
    font: normal normal normal 15px/20px Uniform-Medium !important;
  }
  .childInfoBox {
    height: auto !important;
  }
  .deviceBox {
    height: auto !important;
  }
  .absenceNumber {
    font: normal normal normal 40px/46px Uniform-Medium;
    padding: 0px 10px 10px 10px;
  }
  .absesnes {
    font: normal normal normal 9px Uniform-Medium;
    letter-spacing: 0px;
    color: #a7a7a7;
    padding: 0px 5px;
  }
  .excusedUnexcused {
    display: flex;
    flex-direction: column;
    padding-left: 5px !important;
  }
  .AttendancePaper {
    margin-top: 10px;
  }
  .mobileInfo {
    padding: 2px 0px 10px 0px !important;
  }

  .compTitleD {
    font: normal normal normal 26px/31px Uniform;
  }
  .description {
    font: normal normal normal 14px/17px Uniform;
  }
  .SecTitle {
    font: normal normal bold 16px/20px Uniform;
  }
  .subjectRow {
    font: normal normal normal 13px/16px Uniform;
    width: 70%;
    padding-top: 0px;
  }
  .AcademicPaperRecord {
    padding: 15px 15px 25px 15px !important;
  }
  .gradePerSubjectMobile {
    min-width: 50%;
  }
  .roundedDiv {
    width: 20px;
    height: 12px;
    margin: 3px;
    border-radius: 4px;
  }
  .infoIcon {
    width: 14px;
    height: 14px;
  }
  .GradeRow {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
  }
  .MAP_Legend {
    padding: 0px !important;
    padding-left: 0px !important;
    margin-top: 20px !important;
    flex-direction: column;
    justify-content: center;
  }
  .WithBorder {
    border-right: none !important;
    padding-right: 0px;
  }
  .studentMap {
    width: 15px;
    height: 15px;
    margin: 3px;
  }
  .mobileLegendPlaceHolder {
    min-height: 100px;
  }
  .roundedlegend {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 5px !important;
  }
  .legendText {
    padding: 5px 0px !important;
    width: 100px !important;
    color: #020a58;
    font: normal normal normal 9px/12px Uniform !important;
  }
  .BenchMark {
    width: 15px;
    height: 15px;
    margin: 3px;
  }
  .p20 {
    padding: 5px;
  }
  .MChart {
    width: 300px;
    height: 550px;
  }
  /* resources */
  .resourceNumber {
    display: none;
  }
  .resourceHeader {
    font: normal normal normal 14px/17px Uniform;
    font-weight: bold;
  }
  .resourceImage {
    width: 113px;
    height: 70px;
  }
  .innerMapPaper {
    margin-top: 20px !important;
    padding: 5px;
    padding-top: 20px !important;
  }
  .ResourcesRow {
    flex-direction: column;
    padding: 5px;
  }
  .mapBox {
    width: 300px;
    border: 2px solid #eee;
    display: inline-block;
    overflow: hidden;
  }
  .MobileImage {
    width: 90%;
    height: 200px;
    border-radius: 8px;
  }
  .sButton {
    font: normal normal normal 9px/11px Uniform;
    padding: 5px 10px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    border-radius: 0px !important;
  }
  .DashboardResourceImage {
    width: 100% !important ;
    height: 200px;
  }
  .sButtonIcon {
    /* width: 11px !important;
    height: 11px !important; */
  }

  .tourPopUp {
    width: 80% !important ;
    /* height: 150px; */
    max-height: 500px !important ;
    max-width: 100% !important ;
    border-radius: 8px !important ;
  }
  .LabelT {
    width: 55px;
    min-width: 55px;
  }
  .w150 {
    width: 260px;
  }
  .lengendText {
    font: normal normal normal 8px/10px Uniform;
    padding: 5px 0px !important;
  }
  .mapLeg {
    padding-top: 0px;
  }
  .MapDetails {
    display: flex;
    flex-direction: column;
  }
  .DSectionBox {
    margin-top: 100px;
    margin-left: 0px !important;
  }
  .mapText {
    font: normal normal normal 14px/17px Uniform;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .MarginTop5 {
    margin-top: 25px;
  }
  /* end of media */
}
