@media (-webkit-device-pixel-ratio: 1.25) {
  .SidebarIconsUL {
    margin-top: 70px;
    margin-left: 0px;
  }
  .MobilePosition {
    top: -20px;
    left: -30px;
  }
  .newSideBarClass {
    width: 225px;
  }
  .ARSideBarClass {
    width: 225px;
  }
  .HeaderLogo{
    margin-top: 15px;
    transform: scale(0.75);
  }
  .dec1,.scale08,.schoolEdu,.absoluteTopFooter,.absoluteBottomFooter{
    transform: scale(0.75);
  }
  .attendanceTable th {
    font: normal normal normal 20px Uniform;
  
  }
  .tableText {
    font: normal normal normal 19px Uniform;
 
  }
  .greyColor {
    font: normal normal normal 16px Uniform;

  }
  .loginBtn{
    height: 32px;
    padding: 5px;
  }
  .sidebarTopDiv {
    margin: 20px 0px 20px 15px;
    z-index: 150000;
  }
  .absoluteTopFooter{
    top:-5px;
    right:40px;
  }
  .absoluteBottomFooter{
    position: absolute;
    top:80px;
    right:95px;
  }
  .YellowArrowInBtn{
    margin-left: 150px;
    margin-top:3px;
   }
  .navBarWrapper {
    padding-top: 0px;
  }
  .LoginGateway{
    font: normal normal normal 20px Uniform Bold;
 
  }
  .LoginWelcome{
    font: normal normal normal 40px Uniform Bold;
 
  }
  .LargeFooterView{
    height: 200px;
  }
  .centerScreenO {
    height: 460px;
    width: 80%;
    left: 250px;
    top: 240px;
    padding-right: 10px;
  }

  .ARcenterScreenO {
    height: 460px;
    width: 80%;
    right: 250px;
    top: 240px;
    padding-left: 20px;
  }

  .MinicenterScreenO {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 460px;
    top: 300px;
    left: 110px;
    width: 90%;
    padding-right: 30px;
  }
  .SchoolProfileTitle {
    font: normal normal bold 21px Uniform;
    font-weight: bold;
    padding: 5px;
  }
  .MiniARcenterScreenO {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 460px;
    top: 300px;
    right: 110px;
    width: 90%;
    padding-left: 30px;
  }
  .HeaderBoxPaper {
    padding: 15px;
  }
  .resourceBox {
    width: 290px;
    height: 400px;
  }
  .DashboardResourceImage {
    width: 280px;
    height: 200px;
  }
  .SidebarIcon {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    margin-right: 15px;
    margin-top: 15px;
  }
  .excusedUnexcused {
    font: normal normal normal 11px Uniform;
  }
  .absenceNumber {
    font: normal normal normal 24px Uniform-Medium_Static;
  }
  .UnselectedSidebarIcon {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    margin-top: 15px;
    margin-left: 8px;
  }
  .sideBarButton {
    font: normal normal normal 19px Uniform-Medium;
  }
  .bottomArabicSymbolImg {
    top: 110px;
  }
  .lengendText {
    font: normal normal normal 16px Uniform;
  }
  .bottomArabicSymbolImgAR {
    top: 110px;
  }
 
  .attendanceBtn {
    width: 200px;

  }
  .arabicSymbolImg,.arabicSymbolImgAR {
    top: 15px;
  }
  .bottomArabicSymbolImg, .bottomArabicSymbolImgAR{
    top: 100px;
  } 

  .SectionTitle {
    font: normal normal normal 22px uniform;
  }
  .bottomText {
    font: normal normal normal 21px   Uniform;
    padding: 15px 0;
  }
  .roww {
    font: normal normal normal 16px Uniform;
  }
  .mapText {
    font: normal normal normal 19px Uniform;
  }
  .roundedDiv {
    width: 120px;
    height: 24px;
  }
  .subjectRow {
    width: 450px;
    font: normal normal normal 16px Uniform_Static;
  }
  .compTitleD {
    font: normal normal normal 30px Uniform-Medium;
  }
  .HeaderBox {
    position: fixed;
    top: 25px;
    width: 80%;
    left: 250px;
  }
  .HeaderBoxAR {
    position: fixed;
    top: 25px;
    width: 80%;
    right: 250px;
  }
  .welcomeBack {
    font: normal normal normal 28px Uniform-Medium;
  }
  .messageText {
    font: normal normal normal 20px Uniform-Medium;
  }
}
